import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NavbarContentService } from 'shared/modules/navigation/navbar-content.service';

import { NavbarComponent } from './navbar.component';

@NgModule({
  imports: [CommonModule],
  declarations: [NavbarComponent],
  exports: [NavbarComponent],
})
export class NavbarModule {
  static forRoot(): ModuleWithProviders<NavbarModule> {
    return {
      ngModule: NavbarModule,
      providers: [NavbarContentService],
    };
  }
}
