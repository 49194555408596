import { Type, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigViewerPayload, DataProviderFactory } from 'shared/base/config-viewer/config-viewer-settings.models';
import { isNullOrUndefined } from 'util';

export interface ColumnConfig<T = any> extends ConfigViewerPayload {
  prop?: ExtractedProp;
  name?: string;
  routerLink?: (ExtractedProp | string)[];
  innerHtml?: (ExtractedProp | string)[];
  // type?: TableConfigType;
  enumTypeOptions?: EnumTypeOptions[];
  pipes?: PipeConfig[];
  headerTemplate?: string;
  headerComponent?: Type<any>;
  data?: T;
  width?: string;
}

/**
 * Преобразует enum в структуру из имени для отображения и свойства для моделей/общения с бэкендом
 * @param enumeration
 */
export interface EnumTypeOptions {
  label: string;
  value: any;
}

export function enumToOptions(enumeration: any): EnumTypeOptions[] {
  return Object.keys(enumeration)
    .filter(it => isNaN(+it))
    .map(key => ({ label: key, value: enumeration[key] }));
}

export function enumToTranslatedOptions(enumeration: any, translate: TranslateService): EnumTypeOptions[] {
  return enumToOptions(enumeration)
    .map(it => ({ label: translate.instant(it.label), value: it.value }));
}

/**
 * Типы полей таблицы задаются здесь. Используется в логике фильтров.
 */

// export type TableConfigType = 'string' | 'number' | 'date' | 'enum' | 'boolean';
/**
 * Интерфейс покрывает все случаи для создания пайпа с сопутсвующими настройками
 */
export interface PipeConfig {
  pipe: any;
  args?: any[];
  constructorArgs?: any[];
}

/**
 * Механизм для типизации полей в таблице.
 * Позволяет пользоватся благами типизации в общем случае (вложенные объекты).
 * Пример:
 *  e(p<CampaignViewModel>().winners.sharings) === 'item.winners.sharings' // true
 *  e(p<CampaignViewModel>().nevermind) === 'item.nevermind' // true, но компилятор не даст это сделать.
 */
export type ExtractedProp = string;

export function PropStub<T>(path = []): T {
  return new Proxy(({ path: path }), {
    get(target, prop) {
      if (prop.toString() === 'path') { return target.path; }
      return PropStub(path.concat(prop));
    },
  }) as any;
}

export function e(proxy: any): ExtractedProp {
  return ['item'].concat(proxy.path).join('.');
}

export function f(proxy: any): ExtractedProp {
  return proxy.path.join('.');
}

export function l(proxy: any): ExtractedProp {
  return proxy.path.pop();
}

export interface CellDataPayload<T1 = any, T2 = undefined> {
  column: ColumnConfig<T2>;
  row: T1;
  index: number;

  template?: string;
  component?: Type<any>;
}

export class CellData<T1 = any, T2 = undefined> {
  constructor(
    public column: ColumnConfig<T2>,
    public row: T1,
    public index: number,
  ) {}

  get value() {
    return this.getProperty(this.column.prop);
  }

  get routerLink() {
    if (this.column.routerLink) {
      return this.column.routerLink.map(prop => this.substituteRowValues(prop, this.row));
    }
  }

  get innerHtml() {
    if (this.column.innerHtml) {
      return this.column.innerHtml.map(prop => this.substituteRowValues(prop, this.row)).join('');
    }
  }

  getProperty(prop) {
    return prop && prop.slice(0, 5) === 'item.'
      ? this.getNestedPropertyValue(this.row, prop.slice(5))
      : '';
  }

  private getNestedPropertyValue(value, properties) {
    properties = typeof properties === 'object' ? properties : properties.split('.');
    return properties.length && !isNullOrUndefined(value) ?
      this.getNestedPropertyValue(value[properties[0]], properties.slice(1)) :
      value;
  }

  private substituteRowValues(value, row) {
    if (typeof value !== 'string') {
      return value;
    }
    if (value.slice(0, 5) === 'item.') {
      value = this.getNestedPropertyValue(row, value.slice(5));
      return isNullOrUndefined(value) ? '' : value;
    }
    return value;
  }
}

@Injectable()
export class CellDataFactory extends DataProviderFactory<CellData, CellDataPayload> {
  ctor = CellData;

  create(data: CellDataPayload): CellData {
    return new CellData(data.column, data.row, data.index);
  }
}
