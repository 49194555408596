import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Inject, LOCALE_ID, NgModule } from '@angular/core';
import { HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { AppRoutingModule } from 'dashboard/app-routing.module';
import { AuthModule } from 'dashboard/modules/auth';
import { FILE_STORAGE_API_PREFIX } from 'dashboard/modules/download/download.service';
import { ErrorHandlingModule } from 'dashboard/modules/error-handling';
import { IntlModule } from 'dashboard/modules/intl/intl.module';
import { UserModule } from 'dashboard/modules/user';
import { EnumsService } from 'dashboard/services/enums.service';
import { LoadingModule } from 'shared/modules/loading';
import { ModalService } from 'shared/modules/modal/modal.service';
import { NavigationModule } from 'shared/modules/navigation/navigation.module';
import { SentryErrorHandlerModule } from 'shared/modules/sentry-error-handler/sentry-error-handler.module';

import { dashboardTableQuery, PAGINATION_WRAPPER_QUERY_SERIALIZER } from 'shared/modules/table/pagination-wrapper/project-table-queries';
import { PaginationService } from 'shared/modules/table/paginator/pagination.service';
import { SelectableService } from 'shared/modules/table/table-with-controls/control-cell/selectable.service';
// import { YaMetrikaModule } from 'shared/modules/yametrika.module';
import { setUpCypressAutomocker } from 'shared/utils/set-up-cypress-automocker';
import { release } from '../../../../release';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        ErrorHandlingModule,
        AuthModule,
        UserModule,
        LoadingModule,
        NavigationModule,
        IntlModule.withDefaultLanguage('ru'),
        SentryErrorHandlerModule.withSettings(environment.sentrySettings, release),
        // todo: Я.метрика сейчас мешает открытию попапов в приложении. Приложение начинает виснуть. Нужно найти причину.
        // YaMetrikaModule.withMetrikaId(environment.yaMetrikaId),
        HammerModule,
    ],
    providers: [
        { provide: PAGINATION_WRAPPER_QUERY_SERIALIZER, useValue: dashboardTableQuery },
        { provide: FILE_STORAGE_API_PREFIX, useValue: '/api/filestorage' },
        { provide: APP_INITIALIZER, useValue: setUpCypressAutomocker, multi: true },
        EnumsService,
        SelectableService,
        PaginationService
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(ngSelectConfig: NgSelectConfig, t: TranslateService, @Inject(LOCALE_ID) localeId) {
        this.localizeNgSelect(ngSelectConfig, t);
        this.localizeModals(localeId);
    }

    localizeNgSelect(ngSelectConfig: NgSelectConfig, t: TranslateService) {
        t.onLangChange.subscribe(() => {
            ngSelectConfig.notFoundText = t.instant('NG_SELECT_NOT_FOUND');
            ngSelectConfig.clearAllText = t.instant('NG_SELECT_CLEAR_ALL');
            ngSelectConfig.loadingText = t.instant('NG_SELECT_LOADING');
            ngSelectConfig.typeToSearchText = t.instant('NG_SELECT_TYPE_TO_SEARCH');
        });
    }

    localizeModals(localeId) {
        if (localeId === 'en') {
            ModalService.titles = {
                create: 'Create',
                update: 'Save changes',
                infoTitle: 'Info',
                close: 'Close',
                confirmTitle: 'Confirm action',
                confirmOkButtonTitle: 'Continue',
                confirmCancelButtonTitle: 'Cancel',
            };
        }
    }
}
