import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

@Injectable()
export class LoadingService {
  private isLoadingSubject$ = new BehaviorSubject(0);
  private shouldSkipLoading = 0;

  get isLoading$(): Observable<boolean> {
    return this.isLoadingSubject$
      .asObservable()
      .pipe(
        filter(() => this.shouldSkipLoading === 0),
        map(it => !!it),
        distinctUntilChanged(),
      );
  }

  constructor() {}

  skipLoading(n = 1) {
    this.shouldSkipLoading += n;
  }

  loadingStarted() {
    this.isLoadingSubject$.next(this.isLoadingSubject$.value + 1);
  }

  loadingFinished() {
    this.isLoadingSubject$.next(this.isLoadingSubject$.value - 1);
    this.shouldSkipLoading = Math.max(this.shouldSkipLoading - 1, 0);
  }
}
