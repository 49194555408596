import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandlingInterceptor } from 'dashboard/modules/error-handling/error-handling.interceptor';
import { ErrorHandlingService } from 'dashboard/modules/error-handling/error-handling.service';
import { ToastrModule } from 'ngx-toastr';


@NgModule({
  imports: [
    ToastrModule.forRoot(),
  ],
  providers: [
    ErrorHandlingInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: ErrorHandlingInterceptor,
      multi: true,
    },
    ErrorHandlingService,
  ],
})
export class ErrorHandlingModule {
}
