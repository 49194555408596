import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { APP_INITIALIZER, LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { makeEnum } from 'shared/utils/make-enum';
import { _ } from 'shared/utils/translate.utils';

registerLocaleData(localeRu, 'ru');

export const Lang = makeEnum(_('en'), _('ru'));
export type Lang = keyof typeof Lang;

export function getLanguage(defaultLang: Lang) {
  return localStorage.getItem('lang') || defaultLang;
}

export function setLanguage(lang: Lang) {
  localStorage.setItem('lang', lang);
  window.location.reload();
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?${(new Date).toISOString()}`);
}

export function loadLanguage(translate: TranslateService, lang: string) {
  return () => translate.use(lang).toPromise();
}

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
})
export class IntlModule {
  static withDefaultLanguage(defaultLang: Lang): ModuleWithProviders<IntlModule> {
    return {
      ngModule: IntlModule,
      providers: [
        { provide: 'defaultLang', useValue: defaultLang },
        { provide: LOCALE_ID, useFactory: getLanguage, deps: ['defaultLang'] },
        {
          provide: APP_INITIALIZER,
          useFactory: loadLanguage,
          deps: [TranslateService, LOCALE_ID],
          multi: true,
        },
      ],
    };
  }
}
