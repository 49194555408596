import { Injectable } from '@angular/core';
import { ErrorHandlingInterceptor } from 'dashboard/modules/error-handling/error-handling.interceptor';

@Injectable()
export class ErrorHandlingService {
  constructor(private ehInterceptor: ErrorHandlingInterceptor) {}

  skipError(quantity: number = 1) {
    this.ehInterceptor.errorsToSkip += quantity;
  }
}
