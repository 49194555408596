import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Permission, UserService, User } from 'dashboard/modules/user/user.service';
import { Role } from 'dashboard/pages/auth/roles/roles.module';
import { ArbitraryGridsReference } from 'dashboard/pages/grid-drawing/arbitrary-grids/arbitrary-grids.interfaces';
import { AdvertBrand, BrandedFilm, VimbProductBrand } from 'dashboard/pages/refs/advert-brands/advert-brands.module';
import { adClipTitle } from 'dashboard/pages/refs/advert-brands/branded-film-list/film-card/film-card.component';
import { Agency } from 'dashboard/pages/refs/agencies/agencies.module';
import { Brand } from 'dashboard/pages/refs/brands/brands.module';
import { Client } from 'dashboard/pages/refs/clients/clients.module';
import {
    FinancialChannel, FinancialCities,
    FinancialDirectedChannel,
    FinancialSupplier,
} from 'dashboard/pages/refs/financial-channel/interfaces/financial_channels';
import { FinancialGroup } from 'dashboard/pages/refs/financial-groups/financial-groups.module';
import { TargetAudience } from 'dashboard/pages/refs/target-audiences/target-audiences.module';
import { Unit } from 'dashboard/pages/refs/units/units.module';
import { WeeklyReport, WeeklyReportReports } from 'dashboard/pages/reports/weekly-report/interfaces/weekly-report';
import { InventoryTools } from 'dashboard/pages/tools/inventory/interfaces/inventory-tool';
import { Mediaplan } from 'dashboard/pages/upl/mediaplans/mediaplans.interfaces';
import { City, FilmProduct } from 'dashboard/services/interfaces';
import memo from 'memo-decorator';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { ListResponse } from 'shared/interfaces/list';
import { SelectOptions } from 'shared/modules/forms/options.interface';
import { toPHPDate } from 'shared/utils/form.utils';
import { serializeGet } from 'shared/utils/utils';

export interface VimbTargetAudience {
    id: number;
    vimb_id: number;
    name?: string;
    created_at: Date;
    updated_at: Date;
}

export type CollectionStatePayload<T> = { label: keyof T, value: keyof T, };
export const defaultPayload = {
    value: 'id' as 'id',
    label: 'name' as 'name',
};

export const collectionToEnum = <T>(items: T[], label: keyof T, value: keyof T): SelectOptions =>
    items.map(it => ({ value: it[value], label: it[label] })) as any;

export abstract class CollectionState<T> {
    private initializing = false;

    protected subject = new BehaviorSubject<T[]>(undefined);
    list = this.subject.asObservable().pipe(
        filter(it => {
            const notInitialized = !it;
            if (notInitialized) {
                if (!this.initializing) {
                    this.update()
                        .pipe(first())
                        .subscribe();
                    this.initializing = true;
                }
                return false;
            }
            this.initializing = false;
            return true;
        }),
    );
    options: Observable<SelectOptions>;
    map: Observable<{ [id: string]: string }>;

    constructor(protected payload: CollectionStatePayload<T>) {
        this.options = this.list
            .pipe(map(it => collectionToEnum(it, payload.label, payload.value)));
        this.map = this.options.pipe(map(
            it => it
                .map(it => ({ [it.value as any]: it.label }))
                .reduce((a, b) => ({ ...a, ...b }), {}),
        ));
    }

    abstract update(): Observable<T[]>;
}

export class ServerCollectionState<T> extends CollectionState<T> {
    constructor(
        payload: CollectionStatePayload<T>,
        protected listApiUrl: string,
        protected http: HttpClient,
        private mapper: (v: T) => T = it => it,
    ) {
        super(payload);
    }

    update() {
        return this.http.get<ListResponse<T>>(this.listApiUrl).pipe(
            map(it => it.data.items),
            map(d => d.map(this.mapper)),
            tap((it) => this.subject.next(it)),
        );
    }
}

export class NonListResponseServerCollectionState<T> extends ServerCollectionState<T> {
    update() {
        return this.http.get<{ data: T[] }>(this.listApiUrl).pipe(
            tap(console.log),
            map(it => it.data),
            tap((it) => this.subject.next(it)),
        );
    }
}

export class ListResponseServerCollectionState<T> extends ServerCollectionState<T> {
    update() {
        return this.http.get<{ data: {items: T[]} }>(this.listApiUrl).pipe(
            map(it => it.data.items),
            tap((it) => this.subject.next(it)),
        );
    }
}

export class FilmGroupCollectionState extends ListResponseServerCollectionState<BrandedFilm> {
    options = this.list.pipe(
        map(films => films.map(f => ({
            label: adClipTitle(f, this.translate),
            value: f.id,
        }))),
    );

    constructor(http: HttpClient, private translate: TranslateService, client_id?: number) {
        super(
            defaultPayload,
            client_id
                ? `/api/vimb/film-group/list?client_id=${client_id}`
                : `/api/vimb/film-group/list`,
            http,
        );
    }
}

export class VimbTargetAudiencesCollectionState extends ServerCollectionState<VimbTargetAudience> {
    constructor(http: HttpClient) {
        super(defaultPayload, '/api/vimb/target-audience/vimb/list', http);
    }

    update() {
        let i = 1;
        return this.http.get<ListResponse<VimbTargetAudience>>(this.listApiUrl).pipe(
            map(it => it.data.items
                .sort((a, b) => a.name ? -1 : 1)
                .map(it => ({ ...it, ...{ name: it.name ? it.name : `Неизвестная ЦА #${i++}` } })),
            ),
            tap((it) => this.subject.next(it)),
        );
    }
}

export class DefaultValueCollectionState<T extends { id: any, name: string }> extends CollectionState<T> {
    static fromEnum = (e, translate: TranslateService) => new DefaultValueCollectionState(
        Object.keys(e).map(it => ({
            id: it,
            name: translate.instant(it),
        })),
    )

    constructor(items: T[]) {
        super(defaultPayload);
        this.subject.next(items);
    }

    update() { return this.subject.asObservable(); }
}


@Injectable()
export class EnumsService {
    roles: CollectionState<Role>;
    permissions: CollectionState<Permission>;
    clients: CollectionState<Client>;
    cities: CollectionState<City>;
    brands: CollectionState<Brand>;
    adBrands: CollectionState<AdvertBrand>;
    filmProducts: CollectionState<FilmProduct>;
    vimbProductBrands: CollectionState<VimbProductBrand>;
    agencies: CollectionState<Agency>;
    units: CollectionState<Unit>;
    financialGroups: CollectionState<FinancialGroup>;
    mediaplans: CollectionState<Mediaplan>;
    targetAudiences: CollectionState<TargetAudience>;
    vimbTargetAudiences: CollectionState<VimbTargetAudience>;
    adwhTargetAudiences: CollectionState<VimbTargetAudience>;
    mainChannels: CollectionState<any>;
    channels: CollectionState<any>;
    inventoryTools: CollectionState<InventoryTools>;
    weeklyReport: CollectionState<WeeklyReport>;
    financialChannels: CollectionState<FinancialChannel>;
    financialDirectedChannels: CollectionState<FinancialDirectedChannel>;
    financialCities: CollectionState<FinancialCities>;
    arbitraryGrids: CollectionState<ArbitraryGridsReference>;
    buyers: CollectionState<User>;
    suppliers: CollectionState<FinancialSupplier>;
    // adClips: CollectionState<BrandedFilm>;

    // tslint:disable-next-line:max-line-length
    constructor(private http: HttpClient, @Inject(LOCALE_ID) private locale: string, private translate: TranslateService, user: UserService) {
        user.currentUser$.subscribe(() => this.init(http));
    }

    private init(http: HttpClient) {
        /*User*/
        this.roles = new ServerCollectionState<Role>(defaultPayload, '/api/user/role/list', http);
        this.permissions = new ServerCollectionState<Permission>({
            value: 'id',
            label: 'name',
        }, '/api/user/permission/list', http);
        /*Vimb*/
        this.clients = this.getClientsCollectionStateForRange();
        this.adBrands = this.getAdBrandsCollectionStateForRange();
        this.filmProducts = this.getFilmProductsCollectionState();
        this.vimbProductBrands = this.getVimbProductBrandsCollectionState();
        this.brands = new ServerCollectionState<Brand>(defaultPayload, '/api/vimb/brand/list', http);
        this.targetAudiences = new ServerCollectionState(defaultPayload, '/api/vimb/target-audience/list', http);
        this.mainChannels = new ServerCollectionState<any>(defaultPayload, '/api/vimb/main-channel/list', http);
        this.channels = new ServerCollectionState<any>(defaultPayload, '/api/vimb/channel/list', http);
        this.financialChannels = new ServerCollectionState<FinancialChannel>(defaultPayload, '/api/finance/financial-channel/list', http);
        this.financialDirectedChannels = new ServerCollectionState<FinancialDirectedChannel>(defaultPayload, '/api/finance/financial-directed-channel/list', http);
        this.financialCities = new ServerCollectionState<FinancialCities>(defaultPayload, '/api/finance/financial-city/list', http);
        this.suppliers = new ServerCollectionState<FinancialSupplier>(defaultPayload, '/api/finance/financial-supplier/list', http);
        this.arbitraryGrids = new ServerCollectionState<any>(defaultPayload, '/api/grid-drawing/arbitrary-drawing/list', http);
        this.cities = this.getCities();
        this.inventoryTools = new ServerCollectionState<InventoryTools>(defaultPayload, '/api/inventory-availability/template/list', http);
        this.weeklyReport = new ServerCollectionState<WeeklyReport>(defaultPayload, '/api/weekly-report/template/list', http);
        this.vimbTargetAudiences = new VimbTargetAudiencesCollectionState(http);
        this.adwhTargetAudiences =
            new ServerCollectionState<VimbTargetAudience>(defaultPayload, '/api/palomars/target-audience/adwh/list', http);
        this.agencies = new ServerCollectionState<Agency>(defaultPayload, '/api/company/agency/list', http);
        this.units = new ServerCollectionState<Unit>(defaultPayload, '/api/company/unit/list', http);
        this.financialGroups = new ServerCollectionState<FinancialGroup>(defaultPayload, '/api/vimb/ad-brand/financial-group/list', http);
        this.mediaplans = new ServerCollectionState<Mediaplan>(defaultPayload, '/api/plan/list', http);
        // this.adClips = new NonListResponseServerCollectionState<BrandedFilm>(defaultPayload, `/api/vimb/branded-film/list`, http);
        this.buyers = this.getBuyersList();
    }

    @memo(client_id => client_id)
    getFilmsCollectionStateForClient(client_id?: number) {
        return new FilmGroupCollectionState(this.http, this.translate, client_id);
    }

    getFilmProductsCollectionState(client_id_list?: number[], dateRange?: [Date, Date], superdirection_id_list?: number[]) {
        let url = `/api/vimb/film-product/list`;
        if (dateRange?.length) {
            const [active_date_from, active_date_to] = dateRange.map(it => toPHPDate(it));
            url += `?${serializeGet({ active_date_from, active_date_to })}`;
        }
        if (client_id_list?.length) {
            const params = { client_id_list };
            url += `?${serializeGet(params)}`;
        }
        if (superdirection_id_list?.length) {
            const params = { superdirection_id_list };
            url += `?${serializeGet(params)}`;
        }
        return new ServerCollectionState<FilmProduct>(defaultPayload, url, this.http);
    }

    getClientsCollectionStateForRange(dateRange?: [Date, Date]) {
        let url = `/api/vimb/client/list`;
        if (dateRange && dateRange.length) {
            const [active_date_from, active_date_to] = dateRange.map(it => toPHPDate(it));
            url += `?${serializeGet({ active_date_from, active_date_to })}`;
        }

        return new ServerCollectionState<Client>(defaultPayload, url, this.http);

    }

    getBuyersList () {
        const defaultPayloadforBuyer = {
            value: 'id' as 'id',
            label: 'full_name' as 'full_name',
        };
        let url = 'api/user/list?only_buyers=1';

        return new ServerCollectionState<User>(defaultPayloadforBuyer, url, this.http);
    }

    getClientsCollectionStateForRangeAndSuperdirectionId(dateRange?: [Date, Date], superdirection_id?: number) {
        let url = `/api/vimb/client/list`;
        let params = {};
        if (dateRange && dateRange.length) {
            const [active_date_from, active_date_to] = dateRange.map(it => toPHPDate(it));
            params = {...params, active_date_from, active_date_to};
        }
        if (superdirection_id !== null) {
            const active_superdirection = superdirection_id;
            params = {...params, active_superdirection};
        }
        url += `?${serializeGet( params )}`;
        return new ServerCollectionState<Client>(defaultPayload, url, this.http);
    }

    getAdBrandsCollectionStateForRange(dateRange?: [Date, Date]) {
        const advertBrandPayload = {
            value: 'id' as 'id',
            label: 'name' as 'name',
        };

        let url = `/api/vimb/ad-brand/list`;
        if (dateRange && dateRange.length) {
            const [active_date_from, active_date_to] = dateRange.map(it => toPHPDate(it));
            url += `?${serializeGet({ active_date_from, active_date_to })}`;
        }

        return new ServerCollectionState<AdvertBrand>(advertBrandPayload, url, this.http);
    }

    getAdBrandsCollectionStateForClients(client_id_list?: number[]) {
        const advertBrandPayload = {
            value: 'id' as 'id',
            label: 'name' as 'name',
        };

        let url = `/api/vimb/ad-brand/list`;
        if (client_id_list.length) {
            const params = { client_id_list };
            url += `?${serializeGet(params)}`;
        }

        return new ServerCollectionState<AdvertBrand>(advertBrandPayload, url, this.http);
    }

    getVimbProductBrandsCollectionState() {
        const vimbProductBrandPayload = {
            value: 'id' as 'id',
            label: 'name' as 'name',
        };

        const url = `/api/vics/product-brand/list`;

        return new ServerCollectionState<VimbProductBrand>(vimbProductBrandPayload, url, this.http);
    }

    @memo((...args) => JSON.stringify(args))
    getCities(dateRange?: [Date, Date], clientId?: number, brandId?: number, activeSuperdirectionId?: number, superdirectionId?: number ) {
        let url = '/api/vimb/city/list';
        const urlParams: Record<string, string | number> = {};
        if (dateRange && dateRange.length) {
            const [active_date_from, active_date_to] = dateRange.map(it => toPHPDate(it));
            urlParams.active_date_from = active_date_from;
            urlParams.active_date_to = active_date_to;
        }
        if (clientId) {
            urlParams['active_client_id_list[]'] = clientId;
        }
        if (brandId) {
            urlParams['active_ad_brand_id_list[]'] = brandId;
        }
        if (activeSuperdirectionId) {
            urlParams['active_superdirection'] = activeSuperdirectionId;
        }
        if (superdirectionId) {
            urlParams['superdirection_id_list[]'] = superdirectionId;
        }
        const urlParamsString = serializeGet(urlParams);
            if (urlParamsString) {
            url += `?${urlParamsString}`;
            }

        return new ServerCollectionState<City>(
            defaultPayload, url, this.http,
            // it => (it.showAtTrpPage = (it.subdirection === AffinitySubdirection.MOSCOW || it.subdirection === AffinitySubdirection.NATIONAL), it),
            it => (it.showAtTrpPage = true, it),
        );

}
}
