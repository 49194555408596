import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { Keyable } from 'shared/types';

export class Utils {
    static dateToBackend(date) {
        if (typeof date === 'string') {
            date = new Date(Date.parse(date));
        }
        const dateCopy = new Date(date.getTime());
        dateCopy.setHours(0, 0, 0, 0);
        dateCopy.setHours(-dateCopy.getTimezoneOffset() / 60);
        return dateCopy.toISOString().split('T')[0];
    }

    static timeToBackend(date) {
        const dateCopy = new Date(date.getTime());
        return dateCopy.toISOString().split('T')[1].split(':').slice(0, 2).join(':');
    }

    static isToday(date): boolean {
        return this.dateToBackend(Utils.now) === this.dateToBackend(date);
    }

    static dateIsLesser(date): boolean {
        return this.dateToBackend(Utils.now) > this.dateToBackend(date);
    }

    static get now() {
        const today = new Date;
        today.setHours(0, 0, 0, 0);
        return today;
    }

    static currentOrNextYear() {
        const today = new Date;
        return today.getMonth() === 11 ? today.getFullYear() + 1 : today.getFullYear();
    }

    static fromToday(n: number): Date {
        const date = Utils.now;
        date.setDate(date.getDate() + n);
        return date;
    }

    public static monthsOfYear(year: number) {

        // return Array.from({ length }, (_, i) => start + i)
        //     .map(num => {});

        return Array.from({ length: 12 }, (v, k) => k).map(monthNum => {
            const month = new Date;
            month.setFullYear(year, monthNum, 1);
            if (monthNum === 11) {
                month.setDate(31);
            }
            return month;
        });

        // const months = [];
        // for (let i = 0; i <= 11; i++) {
        //     const month = new Date;
        //     month.setFullYear(year, i, 1);
        //
        //     if (i === 11) {
        //         month.setDate(31);
        //     }
        //
        //     months.push(month);
        // }
        // console.log(months);
        // return months;
    }

    public static deepCopy<T>(source: T): T {
        return Array.isArray(source)
            ? source.map(item => this.deepCopy(item))
            : source instanceof Date
                ? new Date(source.getTime())
                : source && typeof source === 'object'
                    ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
                        Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop));
                        o[prop] = this.deepCopy(source[prop]);
                        return o;
                    }, Object.create(Object.getPrototypeOf(source)))
                    : source as T;
    }

}

let uid = 0;
export const uniqueid = () => ++uid;

export const serializeGet = function(obj) {
    const str = [];
    for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            const value = obj[prop];
            if (typeof value === 'object') {
                if (Array.isArray(value)) {
                    prop = prop + '[]';
                    value.forEach((value) => str.push(`${encodeURIComponent(prop)}=${encodeURIComponent(value)}`));
                }
            } else if (typeof value === 'boolean') {
                str.push(`${encodeURIComponent(prop)}=${value === true ? 1 : 0}`);
            } else if (value) {
                str.push(`${encodeURIComponent(prop)}=${encodeURIComponent(value)}`);
            }
        }
    }
    return str.join('&');
};

export const thisYear = () => {
    const from = new Date;
    const to = new Date;
    from.setMonth(0, 1);
    to.setMonth(11, 31);
    return [from, to];
};
export const thisMonth = (max: Date = null): [Date, Date] => {
    let from;
    let to;
    from = new Date;
    to = new Date;
    if (max && from.getMonth() >= max.getMonth()) {
        from = new Date(max);
        to = new Date(max);
    }
    from.setMonth(from.getMonth(), 1);
    to.setMonth(from.getMonth() + 1, 0);
    [from, to].forEach(d => d.setHours(0, 0, 0, 0));
    return [from, to];
};

export const removeDuplicates = <T>(array: T[]): T[] => Array.from(new Set(array));
export const mergeArrays = <T>(array: T[][]): T[] => [].concat(...array);
export const mergeAndRemoveDuplicates = <T>(array: T[][]): T[] => removeDuplicates(mergeArrays(array));

export const arrayIsNullOrEmpty = (arr) => arr === null || arr === undefined || arr.length === 0;
export const arrayHaveLength = (arr, n: number) => !(arr === null || arr === undefined) && arr.length === n;

export const deleteProviderValue = (v: Function, inModule, NOT_YET) => {
    const diMap: Map<any, any> = inModule.records;
    diMap.get(v).value = NOT_YET;
};

export function saveAs(blobOrString: Blob, filename: string);
export function saveAs(blobOrString: string, filename?: string);
export function saveAs(blobOrString: Blob | string, filename?: string) {
    const link = document.createElement('a');

    let url: string;
    if (typeof blobOrString === 'string') {
        url = blobOrString;
    } else {
        url = URL.createObjectURL(blobOrString);
    }

    link.setAttribute('href', url);
    if (filename) {
        link.setAttribute('download', filename);
    }
    document.body.appendChild(link); // Required for FF

    link.click();
    requestAnimationFrame(() => {
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
    });
}

// tslint:disable max-line-length
export function listToObject<T, K extends Keyable>(l: T[], keyCb: (e: T, i?: number) => K): { [key in K]: T };
export function listToObject<T, R, K extends Keyable>(l: T[], keyCb: (e: T, i?: number) => K, transformCb: (e: T, i?: number) => R): { [key in K]: R };
export function listToObject<T, R, K extends Keyable>(l: T[], keyCb: (e: T, i?: number) => K, transformCb?: (e: T, i?: number) => R): { [key in K]: R } | { [key in K]: T } {
    const result: any = {};
    if (transformCb) {
        l.forEach((v, i) => {
            const key = keyCb(v, i);
            result[key] = transformCb(v, i);
        });
    } else {
        l.forEach((v, i) => {
            const key = keyCb(v, i);
            result[key] = v;
        });
    }
    return result;
}

export function textToClipboard(text: string) {
    const area = document.createElement('textarea');
    area.style.position = 'fixed';
    area.value = text;
    document.body.append(area);
    area.select();
    document.execCommand('copy');
    area.remove();
}

export function isElement(n: Node): n is Element {
    return !!n['tagName'];
}

const defaultGetFileOptions = {
    accept: 'image/*',
    multiple: false,
};

export function getFile(cb: (f: FileList) => void, options = defaultGetFileOptions): void {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = options.accept;
    input.multiple = options.multiple;
    input.style.display = 'none';
    document.body.appendChild(input);

    input.addEventListener('change', () => {
        cb(input.files);
        input.remove();
    });
    input.click();
}

export function downloadFile(url: string) {
    const link = document.createElement('a');
    link.download = '';
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export const nextAnimationFrame = () => new Promise(requestAnimationFrame);

export const tuple = <T extends [void] | {}>(val: T): T => val;

export const formControlValue$ = <T = any>(f: FormControl) => f.valueChanges.pipe(startWith(f.value)) as Observable<T>;

export function toggleElementInArray<T>(e: T, a: T[], x: (ee: T) => number): T[] {
    const index = x(e);
    if (index === -1) {
        a.push(e);
    } else {
        a.splice(index, 1);
    }
    return a;
}


export let md5 = (string) => {

    function RotateLeft(lValue, iShiftBits) {
        return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
    }

    function AddUnsigned(lX, lY) {
        let lX4, lY4, lX8, lY8, lResult;
        lX8 = (lX & 0x80000000);
        lY8 = (lY & 0x80000000);
        lX4 = (lX & 0x40000000);
        lY4 = (lY & 0x40000000);
        lResult = (lX & 0x3FFFFFFF) + (lY & 0x3FFFFFFF);
        if (lX4 & lY4) {
            return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
        }
        if (lX4 | lY4) {
            if (lResult & 0x40000000) {
                return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
            } else {
                return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
            }
        } else {
            return (lResult ^ lX8 ^ lY8);
        }
    }

    function F(x, y, z) {
        return (x & y) | ((~x) & z);
    }

    function G(x, y, z) {
        return (x & z) | (y & (~z));
    }

    function H(x, y, z) {
        return (x ^ y ^ z);
    }

    function I(x, y, z) {
        return (y ^ (x | (~z)));
    }

    function FF(a, b, c, d, x, s, ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(F(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
    }

    function GG(a, b, c, d, x, s, ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(G(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
    }

    function HH(a, b, c, d, x, s, ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(H(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
    }

    function II(a, b, c, d, x, s, ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(I(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
    }

    function ConvertToWordArray(string) {
        let lWordCount;
        let lMessageLength = string.length;
        let lNumberOfWords_temp1 = lMessageLength + 8;
        let lNumberOfWords_temp2 = (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64;
        let lNumberOfWords = (lNumberOfWords_temp2 + 1) * 16;
        let lWordArray = Array(lNumberOfWords - 1);
        let lBytePosition = 0;
        let lByteCount = 0;
        while (lByteCount < lMessageLength) {
            lWordCount = (lByteCount - (lByteCount % 4)) / 4;
            lBytePosition = (lByteCount % 4) * 8;
            lWordArray[lWordCount] = (lWordArray[lWordCount] | (string.charCodeAt(lByteCount) << lBytePosition));
            lByteCount++;
        }
        lWordCount = (lByteCount - (lByteCount % 4)) / 4;
        lBytePosition = (lByteCount % 4) * 8;
        lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
        lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
        lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
        return lWordArray;
    }

    function WordToHex(lValue) {
        let WordToHexValue = '',
            WordToHexValue_temp = '',
            lByte, lCount;
        for (lCount = 0; lCount <= 3; lCount++) {
            lByte = (lValue >>> (lCount * 8)) & 255;
            WordToHexValue_temp = '0' + lByte.toString(16);
            WordToHexValue = WordToHexValue + WordToHexValue_temp.substr(WordToHexValue_temp.length - 2, 2);
        }
        return WordToHexValue;
    }

    function Utf8Encode(string) {
        string = string.replace(/\r\n/g, '\n');
        let utftext = '';

        for (let n = 0; n < string.length; n++) {

            let c = string.charCodeAt(n);

            if (c < 128) {
                utftext += String.fromCharCode(c);
            } else if ((c > 127) && (c < 2048)) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            } else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }

        }

        return utftext;
    }

    let x = Array();
    let k, AA, BB, CC, DD, a, b, c, d;
    let S11 = 7,
        S12 = 12,
        S13 = 17,
        S14 = 22;
    let S21 = 5,
        S22 = 9,
        S23 = 14,
        S24 = 20;
    let S31 = 4,
        S32 = 11,
        S33 = 16,
        S34 = 23;
    let S41 = 6,
        S42 = 10,
        S43 = 15,
        S44 = 21;

    string = Utf8Encode(string);

    x = ConvertToWordArray(string);

    a = 0x67452301;
    b = 0xEFCDAB89;
    c = 0x98BADCFE;
    d = 0x10325476;

    for (k = 0; k < x.length; k += 16) {
        AA = a;
        BB = b;
        CC = c;
        DD = d;
        a = FF(a, b, c, d, x[k + 0], S11, 0xD76AA478);
        d = FF(d, a, b, c, x[k + 1], S12, 0xE8C7B756);
        c = FF(c, d, a, b, x[k + 2], S13, 0x242070DB);
        b = FF(b, c, d, a, x[k + 3], S14, 0xC1BDCEEE);
        a = FF(a, b, c, d, x[k + 4], S11, 0xF57C0FAF);
        d = FF(d, a, b, c, x[k + 5], S12, 0x4787C62A);
        c = FF(c, d, a, b, x[k + 6], S13, 0xA8304613);
        b = FF(b, c, d, a, x[k + 7], S14, 0xFD469501);
        a = FF(a, b, c, d, x[k + 8], S11, 0x698098D8);
        d = FF(d, a, b, c, x[k + 9], S12, 0x8B44F7AF);
        c = FF(c, d, a, b, x[k + 10], S13, 0xFFFF5BB1);
        b = FF(b, c, d, a, x[k + 11], S14, 0x895CD7BE);
        a = FF(a, b, c, d, x[k + 12], S11, 0x6B901122);
        d = FF(d, a, b, c, x[k + 13], S12, 0xFD987193);
        c = FF(c, d, a, b, x[k + 14], S13, 0xA679438E);
        b = FF(b, c, d, a, x[k + 15], S14, 0x49B40821);
        a = GG(a, b, c, d, x[k + 1], S21, 0xF61E2562);
        d = GG(d, a, b, c, x[k + 6], S22, 0xC040B340);
        c = GG(c, d, a, b, x[k + 11], S23, 0x265E5A51);
        b = GG(b, c, d, a, x[k + 0], S24, 0xE9B6C7AA);
        a = GG(a, b, c, d, x[k + 5], S21, 0xD62F105D);
        d = GG(d, a, b, c, x[k + 10], S22, 0x2441453);
        c = GG(c, d, a, b, x[k + 15], S23, 0xD8A1E681);
        b = GG(b, c, d, a, x[k + 4], S24, 0xE7D3FBC8);
        a = GG(a, b, c, d, x[k + 9], S21, 0x21E1CDE6);
        d = GG(d, a, b, c, x[k + 14], S22, 0xC33707D6);
        c = GG(c, d, a, b, x[k + 3], S23, 0xF4D50D87);
        b = GG(b, c, d, a, x[k + 8], S24, 0x455A14ED);
        a = GG(a, b, c, d, x[k + 13], S21, 0xA9E3E905);
        d = GG(d, a, b, c, x[k + 2], S22, 0xFCEFA3F8);
        c = GG(c, d, a, b, x[k + 7], S23, 0x676F02D9);
        b = GG(b, c, d, a, x[k + 12], S24, 0x8D2A4C8A);
        a = HH(a, b, c, d, x[k + 5], S31, 0xFFFA3942);
        d = HH(d, a, b, c, x[k + 8], S32, 0x8771F681);
        c = HH(c, d, a, b, x[k + 11], S33, 0x6D9D6122);
        b = HH(b, c, d, a, x[k + 14], S34, 0xFDE5380C);
        a = HH(a, b, c, d, x[k + 1], S31, 0xA4BEEA44);
        d = HH(d, a, b, c, x[k + 4], S32, 0x4BDECFA9);
        c = HH(c, d, a, b, x[k + 7], S33, 0xF6BB4B60);
        b = HH(b, c, d, a, x[k + 10], S34, 0xBEBFBC70);
        a = HH(a, b, c, d, x[k + 13], S31, 0x289B7EC6);
        d = HH(d, a, b, c, x[k + 0], S32, 0xEAA127FA);
        c = HH(c, d, a, b, x[k + 3], S33, 0xD4EF3085);
        b = HH(b, c, d, a, x[k + 6], S34, 0x4881D05);
        a = HH(a, b, c, d, x[k + 9], S31, 0xD9D4D039);
        d = HH(d, a, b, c, x[k + 12], S32, 0xE6DB99E5);
        c = HH(c, d, a, b, x[k + 15], S33, 0x1FA27CF8);
        b = HH(b, c, d, a, x[k + 2], S34, 0xC4AC5665);
        a = II(a, b, c, d, x[k + 0], S41, 0xF4292244);
        d = II(d, a, b, c, x[k + 7], S42, 0x432AFF97);
        c = II(c, d, a, b, x[k + 14], S43, 0xAB9423A7);
        b = II(b, c, d, a, x[k + 5], S44, 0xFC93A039);
        a = II(a, b, c, d, x[k + 12], S41, 0x655B59C3);
        d = II(d, a, b, c, x[k + 3], S42, 0x8F0CCC92);
        c = II(c, d, a, b, x[k + 10], S43, 0xFFEFF47D);
        b = II(b, c, d, a, x[k + 1], S44, 0x85845DD1);
        a = II(a, b, c, d, x[k + 8], S41, 0x6FA87E4F);
        d = II(d, a, b, c, x[k + 15], S42, 0xFE2CE6E0);
        c = II(c, d, a, b, x[k + 6], S43, 0xA3014314);
        b = II(b, c, d, a, x[k + 13], S44, 0x4E0811A1);
        a = II(a, b, c, d, x[k + 4], S41, 0xF7537E82);
        d = II(d, a, b, c, x[k + 11], S42, 0xBD3AF235);
        c = II(c, d, a, b, x[k + 2], S43, 0x2AD7D2BB);
        b = II(b, c, d, a, x[k + 9], S44, 0xEB86D391);
        a = AddUnsigned(a, AA);
        b = AddUnsigned(b, BB);
        c = AddUnsigned(c, CC);
        d = AddUnsigned(d, DD);
    }

    const temp = WordToHex(a) + WordToHex(b) + WordToHex(c) + WordToHex(d);

    return temp.toLowerCase();
};


export const declOfNum = (n, textForms) => {
    n = Math.abs(n) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) { return textForms[2]; }
    if (n1 > 1 && n1 < 5) { return textForms[1]; }
    if (n1 === 1) { return textForms[0]; }
    return textForms[2];
};

export const minutesBetween = (firstDate: Date, secondDate: Date): number => {
    const diffMs = (secondDate.getTime() - firstDate.getTime());
    // minutes
    return (diffMs / 1000 / 60);
};

export const getFormatTime = (seconds: number): string => {
    if (seconds < 60) {
        const secondsName = declOfNum(seconds, ['секунда', 'секунды', 'секунд']);
        return `${seconds} ${secondsName}`;
    } else if (seconds >= 60 && seconds <= 3600) {
        const minutes = Math.ceil(seconds / 60);
        const minutesName = declOfNum(minutes, ['минута', 'минуты', 'минут']);
        return `${minutes} ${minutesName}`;
    } else {
        const hourName = 'более 60 минут';
        return `${hourName}`;
    }
};

export function roundTo(n: number, d: number): number {
    return Math.round((n + Number.EPSILON) * Math.pow(10, d)) / Math.pow(10, d);
}