import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrudFetchService } from 'dashboard/chunks/default-crud/abstract-crud-fetch.service';
import { DefaultCrudSettingsConstructor } from 'dashboard/chunks/default-crud/default-crud.settings';
import { Observable } from 'rxjs';
import { ListPayload, ListResponse } from 'shared/interfaces/list';
import { ApiResponse } from 'shared/interfaces/response';
import { map } from 'rxjs/operators';
import { serializeGet } from 'shared/utils/utils';

export class DefaultFetchService<T extends { id } = any> extends CrudFetchService<T> {
  baseUrl: string;

  constructor(
    init: { baseUrl: string },
    protected http: HttpClient,
  ) {
    super();
    this.baseUrl = init.baseUrl;
  }

  getList(payload?: ListPayload): Observable<T[]> {
    return this.http.get<ListResponse<T>>(`${this.baseUrl}/list?${serializeGet(payload)}`)
      .pipe(map(it => it.data.items));
  }

  get(payload): Observable<T> {
    return this.http.get<ApiResponse<T>>(`${this.baseUrl}/${payload.id}`)
      .pipe(map(it => it.data));
  }

  create(payload) {
    return this.http.post(this.baseUrl, payload);
  }

  update(payload) {
    return this.http.put(`${this.baseUrl}/${payload.id}`, payload);
  }

  delete(payload) {
    return this.http.delete(`${this.baseUrl}/${payload.id}`, payload);
  }

}

@Injectable()
export class DefaultCrudFetchService<T extends { id } = any> extends DefaultFetchService<T> {
  constructor(init: DefaultCrudSettingsConstructor, http: HttpClient) {
    super(init, http);
  }
}
