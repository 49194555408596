import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanLoad, NavigationExtras, Params, Route, Router, UrlSegment } from '@angular/router';
import { ApiResponse } from 'shared/interfaces/response';
import { UserService } from 'dashboard/modules/user';
import { Observable, of } from 'rxjs';
import { mapTo, switchMapTo, tap } from 'rxjs/operators';
import { LocalStorage } from 'shared/utils/local-storage.decorator';

export type Auth = { token: string };
export type AuthPayload = { login: string, password: string };

@Injectable()
export class AuthService implements CanLoad {
  private navigateTo: {
    path: string,
    params?: Params,
    hash?: string
  } = { path: '' };

  @LocalStorage({ initialValue: undefined })
  token: string;

  constructor(
    private http: HttpClient,
    protected user: UserService,
    private router: Router,
  ) {
  }

  isLoggedIn$(): Observable<boolean> {
    if (!this.token) {
      return of(false);
    } else {
      return this.user.fetchCurrentUser().pipe(mapTo(true));
    }
  }

  login(payload: AuthPayload) {
    return this.http.post<ApiResponse<Auth>>('/api/user/login', payload)
      .pipe(
        tap(it => this.token = it.data.token),
        switchMapTo(this.user.fetchCurrentUser()),
      );
  }

  removeToken() {
    localStorage.removeItem('token');
  }

  logout() {
    this.router.navigateByUrl('/login');
  }

  navigateToStoredRoute() {
    const navigationExtras: Partial<NavigationExtras> = {};
    if (this.navigateTo.params) {
      Object.assign(navigationExtras, { queryParams: this.navigateTo.params });
    }
    if (this.navigateTo.hash) {
      Object.assign(navigationExtras, { fragment: this.navigateTo.hash });
    }
    this.router.navigate([this.navigateTo.path], navigationExtras);
  }

  storeRoute() {
    const { pathname, search, hash } = window.location;
    this.navigateTo = {
      path: pathname,
      params: search.slice(1).split('&')
        .map(it => it.split('='))
        .map(([k, v]) => ({ [k]: v }))
        .reduce((a, b) => Object.assign(a, b), {}),
      hash: hash.slice(1),
    };
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.isLoggedIn$()
      .pipe(tap(isLoggedIn => {
        if (!isLoggedIn) {
          this.storeRoute();
          this.router.navigate(['/', 'login']);
        }
      }));
  }
}
