import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BrandedFilm } from 'dashboard/pages/refs/advert-brands/advert-brands.module';

export const adClipTitle = (film: BrandedFilm, translate: TranslateService) =>
  `${ film.name } / ${ film.duration } ${ translate.instant('SEC') } / ${ film.version }`;

@Component({
  selector: 'app-film-card',
  template: `
      <app-expandable-card [expansionPanel]="true" >
          <ng-template expandableCardHeader>
              <span class="mr-3 flex-grow-1 text-overflow-ellipsis" [title]="film.name">
                  {{ title }}
              </span>

          </ng-template>
          <ng-template expandableCardBody>
              <dl>
                  <dt>
                      <i class="fa fa-fw fa-clock"></i>
                      {{ 'ADS_DURATION' | translate }}
                  </dt>
                  <dd>{{ film.duration }} {{ 'SEC' | translate }}</dd>
              </dl>
              <dl>
                  <dt>
                      <i class="fa fa-fw fa-history"></i>
                      {{ 'ADS_VERSION' | translate }}
                  </dt>
                  <dd>{{ film.version }}</dd>
              </dl>
              <dl *ngIf="film.has_duplicates">
                  <dt>
                      <i class="fa fa-fw fa-copyright"></i>
                      {{ 'PRODUCT_BRAND' | translate }}
                  </dt>
                  <dd>{{ film.product_brand_name }}</dd>
              </dl>
              <dl>
                  <dt>
                      <i class="fa fa-fw fa-splotch"></i>
                      {{ 'SPOT_COUNT' | translate }}
                  </dt>
                  <dd>{{ film.spot_count }}</dd>
              </dl>
          </ng-template>
      </app-expandable-card>
  `,
  styleUrls: ['./film-card.component.scss']
})
export class FilmCardComponent implements OnChanges {
  @Input()
  film: BrandedFilm;
  title: string;
  constructor(private translate: TranslateService) {
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.title = adClipTitle(this.film, this.translate);
  }

}
