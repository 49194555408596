import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { AuthService } from 'dashboard/modules/auth';
import { DefaultRouteReuseStrategy, VariableRouteReuseStrategy } from 'shared/route-reuse-strategies/variable-route-reuse-strategy';

const routes: Routes = [
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  { path: 'download', loadChildren: () => import('./modules/download/download.module').then(m => m.DownloadModule) },
  { path: '', loadChildren: () => import('./main-layout/main-layout.module').then(m => m.MainLayoutModule), canLoad: [AuthService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    { provide: RouteReuseStrategy, useClass: VariableRouteReuseStrategy },
  ],
})
export class AppRoutingModule {
  constructor() {
    VariableRouteReuseStrategy.setRouteReuseStrategy(new DefaultRouteReuseStrategy());
  }
}
