import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BindObservable } from 'bind-observable';
import { ApiResponse } from 'shared/interfaces/response';
import { ClientsFetchService } from 'dashboard/pages/refs/clients/clients-fetch.service';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { PropStub } from 'shared/modules/table/table-config.model';
import { fromPHPDate } from 'shared/utils/form.utils';
import { isBoolean } from 'util';
import { DefaultPermissions, PermissionsTree, ViewControl } from './permissions.interface';

const toKebabCaseFromSnakeCase = (s: string) => [].map.call(s, (v) => v.toUpperCase() === v ? `-${v.toLowerCase()}` : v).join('');

export interface Permission {
    id: number;
    alias: string;
    name: string;
}

export interface User {
    id: number;
    first_name: string;
    last_name: string;
    login: string;
    email: string;
    password: string;
    phone: string;
    role_id: number;
    role_name: string;
    blocked: boolean;
    access_to_all_clients: boolean;
    permission_alias_list: string[];
    client_id_list: number[];
    power_bi_report_list: PowerBiLink[];
    max_report_date: Date | string;
    created_at: Date;
    updated_at: Date;
    deleted_at?: any;
    full_name: string;
}

export type ExtendedUser = User & { pictureUrl?: string, clientId?: number };

export interface PowerBiLink {
    name: string;
    url: string;
}

export const permStub = PropStub<PermissionsTree>();

export function fromViewControlStub(v: ViewControl): DefaultPermissions {
    const recast: any = v;
    return new Proxy<any>(v, { get: () => ({ path: [...recast.path, 'control'] }) });
}

@Injectable()
export class UserService {
    private _currentUser: ExtendedUser;
    get currentUser(): ExtendedUser {
        if (this._currentUser) {
            return this._currentUser;
        }
        this._currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return this._currentUser;
    }

    set currentUser(val: ExtendedUser) {
        this._currentUser = val;
        localStorage.setItem('currentUser', JSON.stringify(val));
        this.currentUser$.next(this._currentUser);
    }

    currentUser$ = new ReplaySubject<ExtendedUser>(1);


    @BindObservable()
    maxReportDate = null;
    maxReportDate$!: Observable<Date>;

    constructor(private http: HttpClient, private clientsFetchService: ClientsFetchService) {
    }

    fetchCurrentUser() {
        return this.http.get<ApiResponse<User>>('/api/user/get-current-user')
            .pipe(
                map(it => it.data),
                switchMap(it => it.client_id_list && it.client_id_list.length === 1 ? this.addPictureUrlAndClientId$(it) : of(it)),
                tap((it) => {
                    this.currentUser = it;
                    this.maxReportDate = it.max_report_date ? fromPHPDate(it.max_report_date as string) : null;
                }),
            );
    }

    private addPictureUrlAndClientId$(user: User): Observable<ExtendedUser> {
        return this.clientsFetchService.get({ id: user.client_id_list[0] }).pipe(
            map(client => ({ ...user, pictureUrl: client.logo_file && client.logo_file.url, clientId: user.client_id_list[0] })),
        );
    }

    removeCurrentUser() {
        this.currentUser = undefined;
        localStorage.removeItem('currentUser');
    }

    hasPermission(val: boolean | any): boolean {
        if (isBoolean(val)) {
            return val;
        }
        const requestedAlias = ((val as any).path as string[]).map(toKebabCaseFromSnakeCase).join('@');
        return this.currentUser.permission_alias_list.indexOf(requestedAlias) !== -1;
    }

    isRoot$(): Observable<boolean> {
        return this.currentUser$.pipe(map((user) => user.role_id === 1));
    }

    hasPermission$(val: boolean): Observable<boolean> {
        return this.currentUser$.pipe(map(() => this.hasPermission(val)));
    }
}
